<template>
  <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
    <!-- 第一板块 -->
    <div class="banner question_banner infoBanBox">
      <img :src="banners.image" alt="" class="infoBan">
      <div class=" w1200 infoBanCont">
        <div class="plcs">
          <div class="txt tion1">
            <p class="fnt_42">{{ competition.title }}</p>
          </div>
          <div class="tion2">
            <p class="fnt_18" v-for="(val, index) in competition.mark" :key="index">{{ val }}</p>
          </div>
          <div class="tion3">
            <ul class="box_tion3">
              <li v-if="competition.bonus!=''&&competition.bonus!=null">
                <p class="fnt_28">{{ competition.bonus }}</p>
                <span class="fnt_16"><img src="@/assets/images/stxq_img1.png" alt="">{{ globalDataArray.siteTemplateLanguage.competition_bonus }}</span>
              </li>
              <li>
                <p class="fnt_28" v-if="competition.teams!=null&&competition.teams!=''">{{ competition.teams }}</p>
                <p class="fnt_28" v-else>0</p>
                <span class="fnt_16"><img src="@/assets/images/stxq_img2.png" alt="">{{ globalDataArray.siteTemplateLanguage.competition_teams }}</span>
              </li>
              <li v-if="competition.type_dictText!=''&&competition.type_dictText!=null">
                <p class="fnt_28">{{ competition.type_dictText }}</p>
                <span class="fnt_16"><img src="@/assets/images/stxq_img3.png" alt="">{{ globalDataArray.siteTemplateLanguage.competition_type }}</span>
              </li>
              <li v-if="competition.signup!=''&&competition.signup!=null">
                <p class="fnt_28">
                  <!-- 已结束 -->
                  <template v-if="competition.signup=='04'">
                    {{ competition.signup_dictText }}
                  </template>
                  <template v-else>
                    <!-- 未开始跟进行中 -->
                    <template v-if="competition.signup=='01' || competition.signup=='02'">
                      {{ competition.signup_dictText }}
                    </template>

                    <!-- 报名中 -->
                    <template v-if="competition.signup=='03' && currentTime < competition.localEndtimeStr">
                      {{ competition.signup_dictText }}
                    </template>

                    <!-- 截止报名 -->
                    <template v-if="competition.signup=='03' && currentTime >= competition.localEndtimeStr">
                      {{ globalDataArray.siteTemplateLanguage.competition_signup_end }}
                    </template>
                  </template>
                </p>
                <span class="fnt_16"><img src="@/assets/images/stxq_img4.png" alt="">{{ globalDataArray.siteTemplateLanguage.competition_state }}</span>
              </li>
            </ul>
          </div>
          <div class="tion4" v-if="competition.signup!=''&&competition.signup!=null">
            <div class="tion4bm">
              <!-- 已结束 -->
              <template v-if="competition.signup=='04'">
                <span>{{ competition.signup_dictText }}</span>
              </template>
              <template v-else>
                <!-- 已经报名显示已报名 -->
                <template v-if="competition.isSignup == 1">
                  <span>{{ globalDataArray.siteTemplateLanguage.competition_signup_yes }}</span>
                </template>
                <template v-else>
                  <template v-if="currentTime < competition.localEndtimeStr">
                    <!-- 未开始跟进行中 -->
                    <template v-if="competition.signup=='01' || competition.signup=='02'">
                      <span>{{ competition.signup_dictText }}</span>
                    </template>

                    <!-- 报名中 -->
                    <template v-if="competition.signup=='03'">
                      <span @click="toSignFun(competition.templateId,competition.categoryId,competition.parentId,competition.teamsUsers)">{{ globalDataArray.siteTemplateLanguage.click_signup }}</span>
                    </template>
                  </template>

                  <!-- 截止报名 -->
                  <template v-else>
                    <span>{{ globalDataArray.siteTemplateLanguage.competition_signup_end }}</span>
                  </template>
                </template>
              </template>
            </div>
            <div class="tion4_txt fnt_16" v-if="competition.organizersLogo!='' || competition.organizers!=''">
              <div class="gjfTit">{{ globalDataArray.siteTemplateLanguage.competition_co_builder }}:</div>
              <div class="logo">
                <template v-if="competition.organizersLogo">
                  <span class="tion4span img" v-for="(val, index) in competition.organizersLogo" :key="index"><img :src="val" alt=""></span>
                </template>
                <template v-else>
                  <span class="tion4span txt" v-for="(val, index) in competition.organizers" :title="val" :key="index">{{ val }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="tion5">
            <p class="tion5_mintop fnt_16" v-if="competition.localStarttime!=''&&competition.localStarttime!=null">
              <img src="@/assets/images/stxq_ks.png" alt="">
              {{ globalDataArray.siteTemplateLanguage.competition_start_signup }}： <span>{{ competition.localStarttime }}</span>
            </p>
            <p class="tion5_mintop fnt_16" v-if="competition.localEndtime!=''&&competition.localEndtime!=null">
              <img src="@/assets/images/stxq_js.png" alt="">
              {{ globalDataArray.siteTemplateLanguage.competition_end_signup }}：<span>{{ competition.localEndtime }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 第二板块 -->
    <div class="question" v-if="competition.progress!=''&&competition.progress!=null">
      <div class="competition  w1200">
        <div class="process" v-for="(item,index) in competition.progress" :class="item.progressStatus == 0 ? 'on' : item.progressStatus == 1 ? 'active' : ''" :key="index">
          <p class="txtp">{{ item.title }}</p>
          <p class="time">{{ item.startDate }}</p>
        </div>

        <!-- <div class="process on">
          <p class="txtp">评审通过</p>
          <p class="time">20230822</p>
        </div>

        <div class="process active">
          <p class="txtp">初赛</p>
          <p class="time">20230821</p>
        </div>

        <div class="process">
          <p class="txtp">复赛</p>
          <p class="time">20230821</p>
        </div>

        <div class="process">
          <p class="txtp">决赛</p>
          <p class="time">20230821</p>
        </div>

        <div class="process">
          <p class="txtp">颁奖典礼</p>
          <p class="time">20230821</p>
        </div>

        <div class="process">
          <p class="txtp">结束</p>
          <p class="time"></p>
        </div> -->
      </div>
    </div>

    <!-- 第三板块 -->
    <div class="contenta">
      <div class="w1200">
        <div class="content_mine" v-if="info">
          <div class="content_left">
            <ul>
              <template v-for="(item,index) in competition.info">
                <template v-if="index == event">
                  <li class="fnt_20 actire" :key="index"><a href="javascript:;" @click="changeEvent(index)">{{ item.title }}</a></li>
                </template>
                <template v-else>
                  <li class="fnt_20" :key="index"><a href="javascript:;" @click="changeEvent(index)">{{ item.title }}</a></li>
                </template>
              </template>
              <!-- <li class="fnt_20"><a href="javascript:;">赛制/赛程</a></li>
              <li class="fnt_20"><a href="javascript:;">作品提交要求</a></li>
              <li class="fnt_20"><a href="javascript:;">评审规则</a></li>
              <li class="fnt_20"><a href="javascript:;">排行榜</a></li> -->
            </ul>
          </div>
          <div class="content_right">
            <div class="content_st">
              <h3 class="fnt_20">{{ info.title }}</h3>
              <div class="txt ck-content" v-html="info.content">
              </div>
            </div>
          </div>
        </div>
        <div style="text-align:center;" v-else>{{ globalDataArray.siteTemplateLanguage.system_data_empty_tips }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { stringToDateTime } from '@/utils/util'
import { previewStInfo } from '@/api/saiti'
import { getAdList } from '@/api/api'
import { message } from 'ant-design-vue'
export default {
  name: 'Previewinfo',
  data() {
    return {
      title: this.globalDataArray.siteInfo.title,
      description: this.globalDataArray.siteInfo.description,
      keywords: this.globalDataArray.siteInfo.keywords,
      oauthUrl: 'https://auth.test.atomgit.com',
      banners: [],
      competition: {},
      currentTime: new Date().getTime(), // 当前地区时区时间
      event: 0,
      info: {},

      signway: false,
      signtype: '',

      formsign: false,
      formteen: false,
      formen: false,
      formenteen: false,

      formapply: false,

      formmsg: false,

      curInfoArray: {
        categoryId: '', // 当前赛题分类id
        competitionId: '', // 当前赛题id
        templateId: '', // 报名模板
        currentLanguge: '',
        teamsUsers: ''
      },

      formxieyi: false
    }
  },
  created() {

  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const that = this
      const adParam = 'alias=newsinfo_banner'
      getAdList(adParam).then(res => {
        if (res.success) {
          that.banners = res.result[0]
        }
      })
      // that.getNowDate()//获取当前时间
      that.getCompetitionInfo()
    },
    changeEvent(e) {
      const that = this
      that.event = e
      that.info = that.competition.info[that.event]
    },
    getCompetitionInfo() {
      const that = this
      const param = 'id=' + this.$route.query.id
      previewStInfo(param).then(res => {
        if (res.success) {
          that.competition = res.result

          if (that.competition.title != '' && that.competition.title != null) {
            that.title = that.competition.title + '-' + that.globalDataArray.siteInfo.title
          }
          if (that.competition.keywords != '' && that.competition.keywords != null) {
            that.keywords = that.competition.keywords
          }
          if (that.competition.description != '' && that.competition.description != null) {
            that.description = that.competition.description
          }

          that.info = that.competition.info[that.event]

          // console.log(that.info,res.result.info,'info')
          if (that.competition.mark) {
            const mark = that.competition.mark
            that.competition.mark = mark.split(',')
          }

          if (that.competition.organizersLogo) {
            const organizersLogo = that.competition.organizersLogo
            that.competition.organizersLogo = organizersLogo.split(',')
          }

          if (that.competition.organizers) {
            const organizers = that.competition.organizers
            that.competition.organizers = organizers.split(',')
          }

          // 将utc时间转为当地时间并作比较
          const endUtcTime = that.competition.endUtcTime
          const localEndtimeStr = new Date(endUtcTime).getTime()
          that.competition['localEndtime'] = stringToDateTime(localEndtimeStr)

          that.competition['localEndtimeStr'] = localEndtimeStr

          const startUtcTime = that.competition.startUtcTime
          const localStarttimeStr = new Date(startUtcTime).getTime()
          that.competition['localStarttime'] = stringToDateTime(localStarttimeStr)

          // 筛选三种状态  过去  距离现在最近  未来
          const progress = that.competition.progress
          const curProgress = []
          progress.forEach((item, index) => {
            const pStartUtcDate = item.startUtcDate
            const pStartLocalStr = new Date(pStartUtcDate).getTime()
            item['pStartLocalStr'] = pStartLocalStr

            if (that.currentTime > pStartLocalStr) {
              curProgress.push(Number(pStartLocalStr))
            } else {
              item['progressStatus'] = '2'
            }
          })

          // 打散获取最大值
          const mathMax = Math.max(...curProgress)

          progress.forEach((item, index) => {
            if (item.pStartLocalStr == mathMax) {
              item['progressStatus'] = '1'
            } else if (item.progressStatus != 2) {
              item['progressStatus'] = '0'
            }
          })
        } else {
          if (res.code == 404) {
            message.error(res.message)
            this.$router.push({ path: '/competition' })
          }
        }
      })
    },
    // getNowDate() {
    //   var date = new Date();
    //   var sign2 = ":";
    //   var year = date.getFullYear() // 年
    //   var month = date.getMonth() + 1; // 月
    //   var day = date.getDate(); // 日
    //   var hour = date.getHours(); // 时
    //   var minutes = date.getMinutes(); // 分
    //   var seconds = date.getSeconds() //秒
    //   var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
    //   var week = weekArr[date.getDay()];
    //   // 给一位数的数据前面加 “0”
    //   if (month >= 1 && month <= 9) {
    //     month = "0" + month;
    //   }
    //   if (day >= 0 && day <= 9) {
    //     day = "0" + day;
    //   }
    //   if (hour >= 0 && hour <= 9) {
    //     hour = "0" + hour;
    //   }
    //   if (minutes >= 0 && minutes <= 9) {
    //     minutes = "0" + minutes;
    //   }
    //   if (seconds >= 0 && seconds <= 9) {
    //     seconds = "0" + seconds;
    //   }

    //   this.currentTime = year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    //   // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    // },

    handleLogin() {
      const redirectUrl = encodeURIComponent(location.href)
      Cookies.set('atom_redirect', redirectUrl, { domain: '.atomgit.com', expires: 180, path: '/', secure: true })
      location.href = this.oauthUrl
    }
  },

  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.description },
        { vmid: 'keywords', name: 'keywords', content: this.keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/problem_des.css'
</style>
